<template>
  <div class="myBox">
    <div class="myTopDiv" v-if="token">
      <div class="editBtn" @click="goEditMy()">编辑资料</div>
      <div class="hearNickname">
        <div class="hear">
          <img v-if="user.headImg" :src="user.headImg" alt />
          <img v-if="!user.headImg" src="../../image/myImg/hearNull.jpg" alt />
        </div>
        <div v-if="user.nickName" class="nickName">{{ user.nickName }}</div>
        <div v-if="!user.nickName" class="nickName">默认用户</div>
      </div>
      <div class="signature">{{ user.desc }}</div>
    </div>
    <div class="topBtn" v-if="!token" @click="goLogin()">登录/注册</div>
    <div class="CommonFunctions">
      <div class="CommonSize">常用功能</div>
      <div class="gongg">
        <div class="gongImg" @click="goMessagePage()">
          <img src="../../image/my/xxtz.png" alt />
          <div class="gongSize">消息中心</div>
        </div>
        <div class="gongImg">
          <img src="../../image/my/ddan.png" alt />
          <div class="gongSize">我的订单</div>
        </div>
        <div class="gongImg" @click="goTrendsPage()">
          <img src="../../image/my/dtai.png" alt />
          <div class="gongSize">我的动态</div>
        </div>
        <div class="gongImg" @click="goCommentPage()">
          <img src="../../image/my/plun.png" alt />
          <div class="gongSize">评论</div>
        </div>
        <div class="gongImg" @click="goLikePage()">
          <img src="../../image/my/dzan.png" alt />
          <div class="gongSize">点赞</div>
        </div>
        <div class="gongImg" @click="goCollectPage()">
          <img src="../../image/my/scang.png" alt />
          <div class="gongSize">收藏</div>
        </div>
        <div class="gongImg" @click="goBrowsePage()">
          <img src="../../image/my/jlu.png" alt />
          <div class="gongSize">浏览记录</div>
        </div>
        <div class="gongImg" @click="goSetIndex()">
          <img src="../../image/my/szhi.png" alt />
          <div class="gongSize">设置</div>
        </div>
      </div>
    </div>
    <div class="serviceBox">
      <div class="serviceSize">平台服务</div>
      <div class="contentBox">
        <div class="content" @click="goCustomer()">
          <img src="../../image/my/kfu.png" alt />
          <div class="sizeStyle">服务中心</div>
        </div>
        <div class="content" @click="goInvitePage()">
          <img src="../../image/my/yqing.png" alt />
          <div class="sizeStyle">校长邀请</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userInfo } from "../../api/lookAt";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      user: {},
      token: "",
    };
  },
  mounted() {
    this.token = Cookies.get("token");
    console.log(this.$store.state.user);
    userInfo({ id: this.$store.state.user }).then((res) => {
      console.log(res, "用户信息");
      this.user = res.data.data;
    });
  },
  methods: {
    goLogin() {
      this.$router.push({ path: "/login" });
    },
    goEditMy() {
      if (Cookies.get("token")) {
        this.$router.push({ path: "/editPage", query: { user: this.user } });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    goMessagePage() {
      if (Cookies.get("token")) {
        this.$router.push({ path: "/messagePage" });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    // 跳转评论页面
    goCommentPage() {
      this.$router.push({ path: "/commentPage" });
    },
    // 跳转点赞页面
    goLikePage() {
      this.$router.push({ path: "/likePage" });
    },
    // 跳转收藏页面
    goCollectPage() {
      this.$router.push({ path: "/collectPage" });
    },
    // 跳转浏览记录
    goBrowsePage() {
      this.$router.push({ path: "/browsePage" });
    },
    // 跳转设置
    goSetIndex() {
      this.$router.push({ path: "/setIndex" });
    },
    // 跳转我的动态
    goTrendsPage() {
      this.$router.push({ path: "trendsPage",query:{user:this.user} });
    },
    // 跳转客服中心
    goCustomer(){
      this.$router.push({path:"/customer"})
    },
    // 跳转校长邀请
    goInvitePage(){
      this.$router.push({path:"/invitePage"})
    }
  },
};
</script>

<style scoped>
.myBox {
  width: 100%;
  height: 1624px;
  background-image: url("../../image/myImg/backImg.png");
  overflow: hidden;
}
.topBtn {
  width: 412px;
  height: 106px;
  background: #0957c3;
  border-radius: 1066px 1066px 1066px 1066px;
  font-size: 28px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 106px;
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 60px;
}
.editBtn {
  width: 128px;
  height: 48px;
  background: rgba(78, 115, 255, 0.1);
  border-radius: 900px 900px 900px 900px;
  opacity: 1;
  font-size: 24px;
  font-weight: 400;
  color: #4e73ff;
  text-align: center;
  line-height: 48px;
  margin-top: 32px;
  margin-left: 590px;
}
.hearNickname {
  display: flex;
  align-items: center;
  margin-top: 32px;
}
.hear {
  width: 128px;
  height: 128px;
  margin-left: 32px;
}
.hear img {
  width: 128px;
  height: 128px;
  border-radius: 50%;
}
.nickName {
  font-size: 40px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 32px;
}
.signature {
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 32px;
  margin-top: 32px;
}
.CommonFunctions {
  width: 686px;
  height: 488px;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-top: 28px;
  margin-left: 32px;
  overflow: hidden;
}
.CommonSize {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 42px;
  margin-top: 42px;
  margin-bottom: 48px;
}
.gongg {
  display: flex;
  flex-wrap: wrap;
}
.gongImg {
  width: 170px;
  height: 136px;
  text-align: center;
  margin-bottom: 32px;
}
.gongImg img {
  width: 80px;
  height: 80px;
}
.gongSize {
  font-size: 24px;
  font-weight: 400;
  color: #0b1526;
  margin-bottom: 32px;
}
.serviceBox {
  width: 686px;
  height: 272px;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-left: 32px;
  margin-top: 36px;
  overflow: hidden;
}
.serviceSize {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-top: 32px;
  margin-left: 32px;
}
.contentBox {
  display: flex;
}
.content {
  width: 180px;
  text-align: center;
  margin-top: 32px;
}
.content img {
  width: 80px;
  height: 80px;
}
.sizeStyle {
  font-size: 24px;
  font-weight: 400;
  color: #0b1526;
}
</style>
